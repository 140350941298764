import { Inject, NgModule } from '@angular/core';
import { CommonModule, Location, DOCUMENT } from '@angular/common';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  MetaObject,
  SeoObject,
  getMetaUrl,
  defaultMetaTags,
  baseMetaTags,
  defaultTitle
} from './meta.config';
import { MetaService } from './meta.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

/**
 * Модуль проставления метатегов.
 *
 * @class
 * @classdesc Модуль, который проставляет/обновляет метатеги на страницах.
 * Метатеги приходят с бэка и мапятся по роуту.
 */
@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class MetaModule {
  /**
   * Объект, содержащий список роутов и их метатеги.
   */
  metaTags: MetaObject = {};
  /**
   * Объект, содержащий SEO-информацию по текущему роуту, для текущего языка.
   */
  currentLangRouterSeo: SeoObject;

  constructor(
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private titleService: Title,
    private location: Location,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
  ) {
    if (!getMetaUrl) {
      this.subscribeToRouter();
      return;
    }

    this.metaService.getMeta().subscribe((res: MetaObject) => {
      this.metaTags = res;
      this.subscribeToRouter();
    });
  }

  /**
   * Геттер получения метатегов текущей страницы.
   *
   * @description Возвращает метатеги для текущей страницы.
   * @returns Возвращает массив метатегов текущей страницы.
   */
  get getCurrentMetaTags(): MetaDefinition[] {
    const url = this.location.path() || '/';

    return [
      ...baseMetaTags,
      ...(this.metaTags[url] ||
        this.currentLangRouterSeo.meta ||
        defaultMetaTags),
      {
        property: 'og:url',
        content: `${this.document.location.origin}${url}`
      },
      {
        property: 'og:image',
        content: `${this.document.location.origin}/ru/assets/img/iPhone.webp`
      }
    ] as MetaDefinition[];
  }

  /**
   * Метод установки метатегов.
   *
   * @param tags - Информация по активному мета-тегу
   */
  updateTags(tags: MetaDefinition[]): void {
    if (!tags) {
      return;
    }
    this.removeAllMetaTags();
    this.meta.addTags(tags);
  }

  /**
   * Метод обновления метатегов текущего роута.
   *
   * @description Обновляет метатеги текущего роута.
   */
  updateCurrentRouteMeta(): void {
    const title = this.currentLangRouterSeo.title || defaultTitle;
    this.titleService.setTitle(title);
    this.updateTags(this.getCurrentMetaTags);
  }

  /**
   * Метод удаления всех метатегов с страницы.
   *
   * @description Удаляет все метатеги, которые указаны в геттере.
   */
  removeAllMetaTags(): void {
    for (let i = 0, length = this.getCurrentMetaTags.length; i < length; i++) {
      const meta: HTMLMetaElement =
        this.document.querySelector(
          `meta[property='${this.getCurrentMetaTags[i].property}']`
        ) ||
        this.document.querySelector(
          `meta[name='${this.getCurrentMetaTags[i].name}']`
        );

      this.meta.removeTagElement(meta);
    }
  }

  /**
   * Метод подписи на события роутера.
   *
   * @description После перехода между страницами происходит обновление метатегов.
   */
  private subscribeToRouter(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(({ seo }) => {
        this.currentLangRouterSeo = (seo && seo[this.translate.currentLang]) || {};
        this.updateCurrentRouteMeta();
      });
  }
}
