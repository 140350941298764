import { REFERRAL_CODE_ID } from '../core';

export const getMobileAppLinkWithRef = (initialCode: string | undefined | null) => {
  const code: string = initialCode || JSON.parse(localStorage.getItem(REFERRAL_CODE_ID))?.code;

  if (code) {
    return {
      googlePlayLinkWithRef: `https://play.google.com/store/apps/details?id=ru.digitax.droid&referrer=referrerCode=${code}`,
      minioLinkWithRef: `https://mediaserver.digitaxfree.ru/media/ref/digitaxfree_${code}.apk `
    };
  }

  return {
    googlePlayLinkWithRef:
      'https://play.google.com/store/apps/details?id=ru.digitax.droid',
    minioLinkWithRef:
      'https://mediaserver.digitaxfree.ru/media/digitax-taxfree-adhoc.apk '
  };
};
