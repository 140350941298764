/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { assign } from 'lodash';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config';
import { AuthService } from 'src/app/core/services/auth.service';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import {
  TaxFreeCheque,
  TaxFreeChequesFilter,
  TaxFreeChequesSort,
  TaxFreeChequesStat
} from 'src/app/core/models/TaxFreeCheque';
import { WebsocketService } from 'src/app/modules/websocket/websocket.service';
import { websocketEvents } from 'src/app/config/websocket.events';
import { TaxFreeChequePaging } from '../models/TaxFreeCheque/TaxFreeChequePaging';
import { ALL_STATUSES, OPERATOR, PAYMENT_OPERATOR } from '../constants';
import { Currency, PaymentOption } from '../models';
import { IGateway } from '@core/models/TaxFreeCheque/Gateways';
import { PaymentGateway } from '../types/models/paymentGateways';

/**
 * Сервис для работы с чеками TaxFree
 */
@Injectable({
  providedIn: 'root'
})
export class TaxFreeChequesService {
  /**
   * Наблюдатель за событием содания чека
   */
  private taxFreeChequeCreatedObserver: Observable<any[]>;
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };
  /**
   * Http Опции с параметром X-Skip-Interceptor
   */
  private httpOptionsWithSkipCacheHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken(),
      // 'X-Skip-Interceptor': ''
    })
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dictionary: DictionaryService,
    private wsService: WebsocketService
  ) {
    this.taxFreeChequeCreatedObserver = this.wsService.on<any[]>([
      websocketEvents.taxFreeChequeCreated
    ]);
  }

  /**
   * Получение чеков для оператора
   *
   * @param taxFreeChequesFilter фильтр
   * @param taxFreeChequesSort сортировка
   * @param page пагинация
   * @param role роль
   * @returns Наблюдателя за получением чеков
   */
  public obtainCheques(
    taxFreeChequesFilter?: TaxFreeChequesFilter,
    taxFreeChequesSort?: TaxFreeChequesSort,
    page: number = 1,
    role?: string
  ): Observable<TaxFreeChequePaging> {
    // Если проставлено значение "все статусы", сбрасываем параметр в null, чтобы не отправлять на сервер
    if (taxFreeChequesFilter && taxFreeChequesFilter.status === ALL_STATUSES) {
      taxFreeChequesFilter.status = null;
    }
    const filterParams = taxFreeChequesFilter
      ? taxFreeChequesFilter.toHttpParams()
      : null;
    const sortParams = taxFreeChequesSort
      ? taxFreeChequesSort.toHttpParams()
      : {};
    const params = assign(filterParams, sortParams, { page, pageSize: 20 });
    const options = Object.assign(this.httpOptionsWithSkipCacheHeader, {
      params
    });
    return this.dictionary
      .obtainCurrencyWithSkipCache()
      .pipe(
        switchMap(currencies =>
          this.http
            .get<any>(this.getChequesPath(role), options)
            .pipe(map(res => this.mapChequeResults(res, currencies)))
        )
      );
  }

  /**
   * Простановка в чеки валюты компенсирования
   *
   * @param cheques чеки
   * @param currencies курсы валют
   * @returns Чеки с проставленными валютами компенсирования
   */
  mapChequeResults(cheques: any, currencies: Currency[]) {
    cheques.elements = cheques.elements.map(taxFreeCheque =>
      this.setToChequeRefundCurrency(taxFreeCheque, currencies)
    );
    return cheques;
  }

  /**
   * Простановка в чек валюты компенсирования
   *
   * @param taxFreeCheque чек
   * @param currencies курсы валют
   * @returns Чек с проставленной валютой компенсирования
   */
  setToChequeRefundCurrency(taxFreeCheque: any, currencies: Currency[]): any {
    const currencyCode = taxFreeCheque.paymentDetails
      ? taxFreeCheque.paymentDetails.currencyCode
      : null;
    const currency = currencies.filter(
      curr => curr.numericCode === currencyCode
    );
    const tfCheque = new TaxFreeCheque(taxFreeCheque);
    tfCheque.refundCurrency = currency[0];
    return tfCheque;
  }

  /**
   * Получение только чеков без простановки валюты компенсирования в ЛК покупателя
   *
   * @param taxFreeChequesFilter фильтр
   * @param taxFreeChequesSort сортировка
   * @param page пагинация
   * @param role роль
   * @returns Наблюдателя за получением чеков
   */
  public obtainOnlyCheques(
    taxFreeChequesFilter?: TaxFreeChequesFilter,
    taxFreeChequesSort?: TaxFreeChequesSort,
    page: number = 1,
    role?: string
  ): Observable<TaxFreeChequePaging> {
    if (taxFreeChequesFilter && taxFreeChequesFilter.status === ALL_STATUSES) {
      taxFreeChequesFilter.status = null;
    }
    const filterParams = taxFreeChequesFilter
      ? taxFreeChequesFilter.toHttpParams()
      : null;
    const sortParams = taxFreeChequesSort
      ? taxFreeChequesSort.toHttpParams()
      : {};
    const params = assign(filterParams, sortParams, { page, pageSize: 10 });
    const options = Object.assign(this.httpOptionsWithSkipCacheHeader, {
      params
    });
    return this.http.get<any>(this.getChequesPath(role), options);
  }

  /**
   * Получение деталей чека по ID
   *
   * @param id id чека
   * @param role роль пользователя
   * @returns Наблюдателя за получением деталей чека
   */
  public obtainChequeById(id: string, role?: string): Observable<any> {
    return this.dictionary.obtainCurrency().pipe(
      mergeMap(currencies =>
        this.http
          .get<any>(this.getChequeByIdPath(id, role), this.httpOptions)
          .pipe(
            map(taxFreeCheque => {
              if (taxFreeCheque && taxFreeCheque.paymentDetails) {
                return this.setToChequeRefundCurrency(
                  taxFreeCheque,
                  currencies
                );
              }
            })
          )
      )
    );
  }

  /**
   * Обновление разрешения на оплату
   *
   * @param id id чека
   * @param state состояние, в который переводят чек (APPROVED или SUSPENDED)
   * @returns Наблюдателя за обновлением разрешения на оплату
   */
  public updatePaymentPermission(id: string, state: string): Observable<any> {
    return this.http.put<any>(
      this.getUpdatePaymentPath(id, state),
      {},
      this.httpOptions
    );
  }

  /**
   * Подтвреждение оплаты
   *
   * @param id id чека
   * @param state даные для подтверждения
   * @returns Наблюдателя за подтвреждением оплаты
   */
  public confirmPayment(id: string, state: any): Observable<any> {
    return this.http.post<any>(
      this.getConfirmPaymentPath(id),
      state,
      this.httpOptions
    );
  }

  /**
   * Получение платежных шлюзов
   *
   * @returns Наблюдателя за получением платежных шлюзов
   */
  public obtainPaymentGateway(): Observable<PaymentGateway[]> {
    return this.http.get<PaymentGateway[]>(
      this.getPaymentGatewayDictionaryPath(),
      this.httpOptions
    );
  }

  /**
   * Получение платежных агентов
   *
   * @returns список платежных агентов
   */
  public getPaymentOperators(): Observable<PaymentGateway[]> {
    return this.http.get<PaymentGateway[]>(
      this.getAllPaymentOperatorsDictionaryPath(),
      this.httpOptions
    );
  }

  /**
   * Изменения статуса платежного агента
   *
   * @param operator платежный агент для изменения статуса
   * @returns список платежных агентов
   */
  public switchPaymentOperatorStatus(
    operator: PaymentGateway
  ): Observable<PaymentGateway[]> {
    return this.http.put<PaymentGateway[]>(
      this.getPaymentOperatorSwitchStatusPath(),
      operator,
      this.httpOptions
    );
  }

  /**
   * Получение номера карты по номеру чека
   *
   * @param chequeId id чека
   * @returns Наблюдателя за получением номера карты
   */
  public obtainCardNumberByChequeId(
    chequeId: string
  ): Observable<PaymentOption> {
    return this.http.get<PaymentOption>(
      this.getCardByChequeIdPath(chequeId),
      this.httpOptions
    );
  }

  /**
   * Получение суммарной статистики в ЛК покупателя
   *
   * @returns Наблюдателя за получением суммарной статистики в ЛК покупателя
   */
  public obtainTaxFreeChequesStat(): Observable<TaxFreeChequesStat> {
    return this.http
      .get<any>(this.getTaxFreeChequesStatPath(), this.httpOptions)
      .pipe(
        map(taxFreeChequesStat => new TaxFreeChequesStat(taxFreeChequesStat))
      );
  }

  /**
   * Получение чеков со статусом ФТС
   *
   * @param params фильтр и сортировка
   * @returns Наблюдателя за получением чеков со статусом ФТС
   */
  public obtainFtsCheques(params: any): Observable<any> {
    const options = { ...this.httpOptions, params };
    return this.http.get(this.getFtsChequesPath(), options);
  }

  /**
   * Получение шаблона ОРТ для реестра чеков ФНС
   *
   * @param year Отчетный год
   * @param taxPeriod Отчетный квартал
   * @param from начало периода для отчёта
   * @param to конец периода для отчёта
   * @returns Наблюдателя шаблона ОРТ для реестра чеков ФНС
   */
  getTemplateFnsFile(year, taxPeriod, from, to) {
    const params = { from, to };

    if (year) {
      params['year'] = year;
    }

    if (taxPeriod) {
      params['taxPeriod'] = taxPeriod;
    }

    const options = {
      ...this.getBlobHttpOptions(),
      params: { ...params }
    };

    return this.http.get<any>(
      `${this.getDownloadTemplateFileFnsPath()}`,
      options
    );
  }

  /**
   * Проверка заполненного шаблона ОРТ на наличие ошибок
   *
   * @param file файл
   * @returns Наблюдателя шаблона ОРТ на наличие ошибок
   */
  getTemplateCheckFile(file: File): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    });

    return this.http.post<any>(`${this.getTemplateCheckPath()}`, file, {
      headers
    });
  }

  /**
   * Перевод чека в состояние Платеж отложен
   *
   * @param id id чека
   */
  pauseCheque(id: string): Observable<TaxFreeCheque> {
    return this.http.put<any>(
      `${this.getPauseChequeCheckPath(id)}`,
      undefined,
      this.httpOptions
    );
  }

  /**
   * Перевод чека в состояние Выбора платежного шлюза
   *
   * @param id id чека
   */
  resumeCheque(id: string): Observable<TaxFreeCheque> {
    return this.http.put<any>(
      `${this.getResumeChequeCheckPath(id)}`,
      undefined,
      this.httpOptions
    );
  }

  /**
   * Выгрузка реестра чеков для ФНС
   *
   * @param file файл
   * @returns Наблюдателя за выгрузкой реестра чеков для ФНС в XML
   */
  getExportToXMLByTemplateFile(file: File): Observable<any> {
    return this.http.post(
      `${this.getDownloadExportChecksXMLByTemplatePath()}`,
      file,
      {
        headers: new HttpHeaders({
          Accept: 'application/xml; charset=windows-1251',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        }),
        observe: 'response',
        responseType: 'arraybuffer'
      }
    );
  }

  /**
   * Выгрузка реестра чеков для ФНС
   *
   * @param year Отчетный год
   * @param taxPeriod Отчетный квартал
   * @param from начало периода для отчёта
   * @param to конец периода для отчёта
   * @param tradingNetworkId ОРТ
   * @param type тип файла
   * @returns Наблюдателя за выгрузкой реестра чеков для ФНС
   */
  getExportChecks(
    year,
    taxPeriod,
    from,
    to,
    tradingNetworkId,
    type: string
  ): Observable<any> {
    if (!['XML', 'XLS'].includes(type)) {
      return of(null);
    }

    const params = {};
    const salt = new Date().getTime();
    const httpParams = {};

    if (from && to) {
      params['from'] = from;
      params['to'] = to;
    }

    if (year) {
      params['year'] = year;
    }

    if (taxPeriod) {
      params['taxPeriod'] = taxPeriod;
    }

    const conditionalParam =
      tradingNetworkId === 'All' && type === 'XLS'
        ? { all: true }
        : { tradingNetworkId };

    Object.keys(params).forEach(
      (key: string) => (httpParams[key] = params[key])
    );

    if (type === 'XML') {
      return this.http.post(
        `${this.getDownloadExportToXMLChecksPath()}`,
        tradingNetworkId === 'All' ? [] : [tradingNetworkId],
        {
          headers: new HttpHeaders({
            Accept: 'application/xml; charset=windows-1251',
            Authorization: 'Bearer ' + this.authService.getAccessToken()
          }),
          observe: 'response',
          responseType: 'arraybuffer',
          params: httpParams
        }
      );
    } else if (type === 'XLS') {
      const options = Object.assign(this.getZIPHttpOptions(), {
        params: httpParams
      });
      options.params = { ...httpParams, ...conditionalParam, type: 'XLS' };
      return this.http.get<any>(
        this.getDownloadExportChecksExcelPath() + `?${salt}`,
        options
      );
    }
  }

  /**
   * Выгрузка реестра чеков для ТС
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   * @param tradingOrganizationId id торговой сети
   * @param type тип файла
   * @returns Наблюдателя за выгрузкой реестра чеков для ТС
   */
  getExportChecksForOrt(
    from: string,
    to: string,
    tradingOrganizationId: string,
    type: string
  ): Observable<any> {
    if (!['XML', 'XLS'].includes(type)) {
      return of(null);
    }
    const salt = new Date().getTime();
    const options = {
      ...this.getZIPHttpOptions(),
      params: { from, to, tradingOrganizationId, type, salt }
    };
    const path =
      type === 'XML'
        ? this.getDownloadExportChecksForORTPath()
        : this.getDownloadExportChecksForORTExcelPath();
    return this.http.get<any>(path, options);
  }
  /**
   * Выгрузка реестра чеков выплаченных в рсб
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   * @returns Наблюдателя за выгрузкой реестра чеков выплаченных в рсб
   */
  getRsbPaidCheques(from: string, to: string): Observable<HttpResponse<any>> {
    const salt = new Date().getTime();
    const path = this.getDownloadRsbPaidChequesPath();

    return this.http.get<HttpResponse<any>>(path, {
      ...this.getBlobWithResponseHttpOptions(),
      params: { from, to, salt }
    });
  }
  /**
   * Выгрузка реестра чеков
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   * @returns Наблюдателя за выгрузкой реестра чеков
   */
  getExportChecksBetweenDates(from: string, to: string): Observable<any> {
    return this.http
      .post<any>(
        `${this.getDownloadReportChecks()}?from=${from}&to=${to}`,
        {},
        this.getBlobHttpOptions()
    )
      .pipe(map(res => this.mapperResults(res)));
  }

  /**
   * Выгрузка реестра товаров
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   * @returns Наблюдателя за выгрузкой реестра товаров
   */
  getExportCommoditiesBetweenDates(from: string, to: string): Observable<any> {
    return this.http
      .post<any>(
        `${this.getDownloadReportCommodities()}?from=${from}&to=${to}`,
        {},
        this.getBlobHttpOptions()
    )
      .pipe(map(res => this.mapperResults(res)));
  }

  /**
   * Выгрузка отчёта для минпромторга
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   * @returns Наблюдателя за выгрузкой реестра чеков
   */
  getMinpromtorgReportBetweenDates(from: string, to: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.getDownloadMinpromtorgReportChecks()}?from=${from}&to=${to}`,
        this.getDownloadingHttpOptions()
    )
      .pipe(map(res => this.mapperResults(res)));
  }
  /**
   * Выгрузка отчёта выпущенных\аннулированных форм
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   *
   * @param tradingNetworkId ид торговой сети
   * @returns Наблюдателя за выгрузкой реестра чеков
   */
  getRefundedCanceledReportBetweenDatesForNetwork(
    from: string,
    to: string,
    tradingNetworkId: string
  ): Observable<any> {
    let params = `from=${from}&to=${to}`;
    if (tradingNetworkId) {
      params = params + `&tradingNetworkId=${tradingNetworkId}`;
    }
    return this.http
      .get<any>(
        `${this.getDownloadRefundedCanceledReportChecks()}?` + params,
        this.getDownloadingHttpOptions()
    )
      .pipe(map(res => this.mapperResults(res)));
  }
  /**
   * Выгрузка отчёта вРеестр на перечисление в банк
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   *
   * @param tradingNetworkId ид торговой сети
   * @returns Наблюдателя за выгрузкой реестра чеков
   */
  getBankTransferRegistryReportBetweenDatesForNetwork(
    from: string,
    to: string,
    tradingNetworkId: string
  ): Observable<any> {
    let params = `from=${from}&to=${to}`;
    if (tradingNetworkId && tradingNetworkId !== 'All') {
      params = params + `&tradingNetworkId=${tradingNetworkId}`;
    }
    return this.http
      .get<any>(
        `${this.getDownloadBankTransferReportChecks()}?` + params,
        this.getZIPHttpOptions());
  }
  /**
   * Выгрузка отчёта выписка банка для орт
   *
   * @param from Дата начала интервала
   * @param to Дата завершения интервала
   *
   * @param tradingNetworkId ид торговой сети
   * @param paymentGatewayName имя платёжного шлюза
   * @returns Наблюдателя за выгрузкой реестра чеков
   */
  getBankStatementReportBetweenDatesForNetwork(
    from: string,
    to: string,
    tradingNetworkId: string,
    paymentGatewayName: string
  ): Observable<HttpResponse<any>> {
    let params = `from=${from}&to=${to}`;
    if (tradingNetworkId && tradingNetworkId !== 'All') {
      params = params + `&tradingNetworkId=${tradingNetworkId}`;
    }
    if (paymentGatewayName && paymentGatewayName !== 'All') {
      params = params + `&paymentGateway=${paymentGatewayName}`;
    }
    return this.http
      .get<any>(
        `${this.getDownloadBankStatementReportChecks()}?` + params,
        this.getZIPHttpOptions());
  }

  /**
   * Маппер результатов
   *
   * @param res даные с сервера
   */
  mapperResults(res: any) {
    let data = null;
    if (res) {
      data = res;
    }
    return data;
  }

  /**
   * Выгрузка реестра чеков с документами от ФТС
   *
   * @param params фильтр
   * @returns Наблюдателя за выгрузкой реестра чеков с документами от ФТС
   */
  getExportChecksFts(params: any): Observable<any> {
    const options = Object.assign(this.getZIPHttpOptions(), { params });
    return this.http.get<any>(`${this.getFtsChequesAllPath()}`, options);
  }

  /**
   * Обновление комментария для чека
   *
   * @param id id чека
   * @param comment комментарий
   * @returns Наблюдателя за обновлением комментария чека
   */
  updateChequeComment(id: string, comment: string): Observable<TaxFreeCheque> {
    return this.http.put<TaxFreeCheque>(
      this.getUpdateChequeCommentPath(),
      { id, comment },
      this.httpOptions
    );
  }

  /**
   * Скачивание чека с отметкой ФТС
   *
   * @param chequeId id чека
   * @returns Наблюдателя за скачиванием чека с отметкой ФТС
   */
  public downloadValidationResult(chequeId: string): Observable<any> {
    return this.http.get<any>(
      this.getDownloadResultsPath(chequeId),
      this.getBlobHttpOptions()
    );
  }

  /**
   * Скачивание XML чека
   *
   * @param chequeId id чека
   * @returns Наблюдателя за скачиванием XML чека
   */
  public downloadChequeFts(chequeId: string): Observable<any> {
    return this.http.get<any>(
      this.getDownloadChequePath(chequeId),
      this.getBlobHttpOptions()
    );
  }

  /**
   * Скачивание квитанции
   *
   * @param chequeId id чека
   * @returns Наблюдателя за скачиванием квитанции
   */
  public getConfirmationDownload(chequeId: string): Observable<any> {
    return this.http.get<any>(
      this.getDownloadConfirmationPath(chequeId),
      this.getBlobHttpOptions()
    );
  }

  /**
   * Сохранение чеков в хранилище
   *
   * @param taxFreeCheques чеки
   */
  public storeCheques(taxFreeCheques: TaxFreeCheque[]) {
    localStorage.setItem('taxFreeCheques', JSON.stringify(taxFreeCheques));
  }

  /**
   * Восстановление чеков из хранилища
   *
   * @returns чеки TaxFree
   */
  public getStoredCheques() {
    return JSON.parse(localStorage.getItem('taxFreeCheques'));
  }

  /**
   * Получение наблюдателя за событием создания чека
   *
   * @returns наблюдателя за событием создания чека
   */
  getChequeCreatedObserver() {
    return this.taxFreeChequeCreatedObserver;
  }

  /**
   * Выгрузка Файла Чека Tax Free от продавца
   *
   * @param id id чека
   * @returns наблюдателя за выгрузкой Файла Чека Tax Free от продавца
   */
  public getOrtDigitalSignatureRequest(
    id: string
  ): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      environment.baseAccountingApiUrl +
        api.operator.ortDigitalSignatureRequestDownload.replace('{id}', id),
      this.getBlobWithResponseHttpOptions()
    );
  }
  /**
   * Выгрузка файла с УКЭП Чека Tax Free
   *
   * @param id id чека
   * @returns наблюдателя за выгрузкой файла с УКЭП Чека Tax Free
   */
  public getOrtDigitalSignatureResponse(
    id: string
  ): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      environment.baseAccountingApiUrl +
        api.operator.ortDigitalSignatureResponseDownload.replace('{id}', id),
      this.getBlobWithResponseHttpOptions()
    );
  }

  /**
   * Создание УКЭП
   *
   * @param id id чека
   * @returns наблюдателя за созданием УКЭП
   */
  public retryOrtDigitalSignature(id: string): Observable<any> {
    return this.http.post(
      environment.baseAccountingApiUrl +
        api.operator.ortDigitalSignatureRetry.replace('{id}', id),
      {},
      this.httpOptions
    );
  }

  /**
   * Получение признака, что создание УКЭП уже было инициализировано
   *
   * @param id id чека
   * @returns наблюдателя за получением признака, что создание УКЭП уже было инициализировано
   */
  public isOrtDigitalSignatureAlreadyRetry(id: string): Observable<any> {
    return this.http.get(
      environment.baseAccountingApiUrl +
        api.operator.ortDigitalSignatureRetry.replace('{id}', id),
      this.httpOptions
    );
  }

  /**
   * обновить кэш реестра чеков и товаров
   *
   * @returns наблюдателя за обновлением кэша, вернёт дату обновления
   */
  public refreshCache(): Observable<any> {
    return this.http.post(
      environment.baseReportingApiUrl + api.operator.refreshCache,
      null,
      this.httpOptions
    );
  }

  /**
   * получить статус
   *
   * @returns наблюдателя за обновлением кэша, вернёт дату обновления
   */
  public getCacheStatus(): Observable<any> {
    return this.http.get(
      environment.baseReportingApiUrl + api.operator.refreshCacheDate,
      this.httpOptions
    );
  }

  /**
   * получить список платёжных агентов
   *
   * @returns наблюдателя за обновлением кэша, вернёт дату обновления
   */
  public getGateways(): Observable<IGateway[]> {
    return this.http.get<IGateway[]>(
      environment.baseAccountingApiUrl + api.operator.gateways,
      this.httpOptions
    );
  }

  /**
   * Метод отправки чеков для проведения оплаты
   *
   * @param gatewayId - id платёжного агента
   * @param chequesIds - список id чеков для оплаты
   * */
  public sendChequesForPayment(
    gatewayId: string,
    chequesIds: string[]
  ): Observable<any> {
    return this.http.post(
      environment.baseAccountingApiUrl +
        api.operator.sendGateway +
        `?gatewayId=${gatewayId}`,
      chequesIds,
      this.httpOptions
    );
  }

  /**
   * Генерация http опций для загрузки blob с сервера
   *
   * @returns http опции для загрузки blob с сервера
   */
  private getBlobHttpOptions() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'blob' as 'json'
    };
  }
  /**
   * Генерация http опций для загрузки файлов
   *
   * @returns http опции
   */
  private getDownloadingHttpOptions() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/vnd.ms-excel',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'blob' as 'json'
    };
  }

  /**
   * Генерация http опций для загрузки blob с параметром observe
   *
   * @returns http опции для загрузки blob с параметром observe
   */
  private getBlobWithResponseHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };
  }

  /**
   * Генерация http опций для загрузки zip
   *
   * @returns http опции для загрузки zip
   */
  private getZIPHttpOptions() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/zip',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'arraybuffer' as 'json'
    };
  }

  /**
   * Генерация URL для получения статистики пользователя
   *
   * @returns URL для получения статистики пользователя
   */
  private getTaxFreeChequesStatPath(): string {
    return (
      environment.baseAccountingApiUrl + api.taxFreeCheque.taxFreeChequeStat
    );
  }

  /**
   * Генерация URL для получения платежных шлюзов
   *
   * @returns URL для получения платежных шлюзов
   */
  private getPaymentGatewayDictionaryPath() {
    return `${environment.baseAccountingApiUrl}/dictionary/payment-gateway/manual`;
  }

  /**
   * Генерация URL для получения платежных агентов
   *
   * @returns URL для получения платежных агентов
   */
  private getAllPaymentOperatorsDictionaryPath() {
    return `${environment.baseAccountingApiUrl}/payment-operator/gateways`;
  }

  /**
   * Генерация URL для изменения статуса платежного агента
   *
   * @returns URL для изменения статуса платежного агента
   */
  private getPaymentOperatorSwitchStatusPath() {
    return `${environment.basePortalApiUrl}/account/trusted/switching`;
  }

  /**
   * Генерация URL для получения номера карты
   *
   * @param chequeId id чека
   * @returns URL для получения номера карты
   */
  private getCardByChequeIdPath(chequeId: string) {
    return `${environment.baseAccountingApiUrl}${api.operator.cardById.replace(
      '{id}',
      chequeId
    )}`;
  }

  /**
   * Генерация URL для получения чеков в соответсвии с ролью пользователя
   *
   * @param role роль пользователя
   * @returns URL для получения чеков в соответсвии с ролью пользователя
   */
  private getChequesPath(role?: string): string {
    let endpoint = api.taxFreeCheque.cheques;
    if (role === OPERATOR || role === PAYMENT_OPERATOR) {
      endpoint = api.operator.cheques;
    }
    return environment.baseAccountingApiUrl + endpoint;
  }

  /**
   * Генерация URL для получения деталей чека в соответсвии с ролью пользователя
   *
   * @param id id чека
   * @param role роль пользователя
   * @returns URL для получения деталей чека в соответсвии с ролью пользователя
   */
  private getChequeByIdPath(id: string, role?: string): string {
    let endpoint = api.taxFreeCheque.chequeById.replace('{id}', id);
    if (role === OPERATOR || role === PAYMENT_OPERATOR) {
      endpoint = api.operator.chequeById.replace('{id}', id);
    }
    return environment.baseAccountingApiUrl + endpoint;
  }

  /**
   * Генерация URL для обновления разрешения на оплату
   *
   * @param id id чека
   * @param state состояние, в который переводят чек (APPROVED или SUSPENDED)
   * @returns URL для обновления разрешения на оплату
   */
  private getUpdatePaymentPath(id: string, state?: string): string {
    const endpoint = api.operator.updatePayment
      .replace('{id}', id)
      .replace('{state}', state);
    return environment.baseAccountingApiUrl + endpoint;
  }
  /**
   * Генерация URL для подтверждения платежа
   *
   * @param id id чека
   * @returns URL для подтверждения платежа
   */
  private getConfirmPaymentPath(id: string): string {
    const endpoint = api.operator.confirmPayment.replace('{id}', id);
    return environment.baseAccountingApiUrl + endpoint;
  }

  /**
   * Генерация URL для получения чеков со статусом ФТС
   *
   * @returns URL для получения чеков со статусом ФТС
   */
  private getFtsChequesPath(): string {
    return environment.baseAccountingApiUrl + api.operator.taxFreeFts;
  }
  /**
   * Генерация URL для скачивания чека с отметкой ФТС
   *
   * @param id id чека
   * @returns URL для скачивания чека с отметкой ФТС
   */
  private getDownloadResultsPath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.operator.ftsResultDownload.replace('{id}', id)
    );
  }
  /**
   * Генерация URL для скачивания XML чека
   *
   * @param id id чека
   * @returns URL для скачивания XML чека
   */
  private getDownloadChequePath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.operator.ftsChequeDownload.replace('{id}', id)
    );
  }
  /**
   * Генерация URL для скачивания квитанции
   *
   * @param id id чека
   * @returns URL для скачивания квитанции
   */
  private getDownloadConfirmationPath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.operator.ftsConfirmDownload.replace('{id}', id)
    );
  }
  /**
   * Генерация URL для выгрузки реестра чеков ФНС в XML
   *
   * @returns URL для выгрузки реестра чеков ФНС в XML
   */
  private getDownloadExportToXMLChecksPath(): string {
    return environment.baseReportingApiUrl + api.taxFreeCheque.exportXMLFns;
  }

  /**
   * Генерация URL для выгрузки реестра чеков ФНС в XML с шаблоном
   *
   * @returns URL для выгрузки реестра чеков ФНС в XML с шаблоном
   */
  private getDownloadExportChecksXMLByTemplatePath(): string {
    return (
      environment.baseReportingApiUrl + api.fnsRegistry.exportXMLByTemplate
    );
  }

  /**
   * Генерация URL для выгрузки реестра чеков ФНС в excel
   *
   * @returns URL для выгрузки реестра чеков ФНС
   */
  private getDownloadExportChecksExcelPath(): string {
    return environment.baseReportingApiUrl + api.taxFreeCheque.exportExcelFns;
  }

  /**
   * Генерация URL для шаблона ФНС
   *
   * @returns URL для шаблона ФНС
   */
  private getDownloadTemplateFileFnsPath(): string {
    return environment.baseReportingApiUrl + api.fnsRegistry.generateTemplate;
  }

  /**
   * Генерация URL для проверки шаблона загрузки
   *
   * @returns URL для проверки шаблона загрузки
   */
  private getTemplateCheckPath(): string {
    return environment.baseReportingApiUrl + api.fnsRegistry.validateTemplate;
  }

  /**
   * Генерация URL для перевода чека в состояние Платеж отложен
   *
   * @param id id чека
   * @returns URL для проверки шаблона загрузки
   */
  private getPauseChequeCheckPath(id: string): string {
    return `${environment.baseAccountingApiUrl}/payment-operator/pause/${id}`;
  }

  /**
   * Генерация URL для перевода чека в состояние Выбор платежного шлюза
   *
   * @param id id чека
   * @returns URL для проверки шаблона загрузки
   */
  private getResumeChequeCheckPath(id: string): string {
    return `${environment.baseAccountingApiUrl}/payment-operator/resume/${id}`;
  }

  /**
   * Генерация URL для выгрузки реестра чеков для ТС
   *
   * @returns URL для выгрузки реестра чеков для ТС
   */
  private getDownloadExportChecksForORTPath(): string {
    return environment.baseAccountingApiUrl + api.operator.downloadCheques;
  }
  /**
   * Генерация URL для выгрузки реестра выплаченных в рсб чеков
   *
   * @returns URL для выгрузки реестра выплаченных в рсб чеков
   */
  private getDownloadRsbPaidChequesPath(): string {
    return (
      environment.baseReportingApiUrl + api.operator.downloadRsbPaidCheques
    );
  }

  /**
   * Генерация URL для выгрузки реестра чеков для ТС в эксель
   *
   * @returns URL для выгрузки реестра чеков для ТС
   */
  private getDownloadExportChecksForORTExcelPath(): string {
    return environment.baseReportingApiUrl + api.operator.exportExcel;
  }
  /**
   * Генерация URL для выгрузки реестра чеков
   *
   * @returns URL для выгрузки реестра чеков
   */
  private getDownloadReportChecks(): string {
    return environment.baseReportingApiUrl + api.report.cheques;
  }

  /**
   * Генерация URL для выгрузки отчёта минпромторг
   *
   * @returns URL для выгрузки отчёта минпромторг
   */
  private getDownloadMinpromtorgReportChecks(): string {
    return environment.baseReportingApiUrl + api.report.minpromtorg;
  }

  /**
   * Генерация URL для выгрузки отчёта выпущенных\аннулированных форм
   *
   * @returns URL для выгрузки отчёта выпущенных\аннулированных форм
   */
  private getDownloadRefundedCanceledReportChecks(): string {
    return environment.baseReportingApiUrl + api.report.refundedCanceled;
  }
  /**
   * Генерация URL для выгрузки отчёта Реестр на перечисление в банк
   *
   * @returns URL для выгрузки отчёта Реестр на перечисление в банк
   */
  private getDownloadBankTransferReportChecks(): string {
    return environment.baseReportingApiUrl + api.report.bankTransferRegistry;
  }
  /**
   * Генерация URL для выгрузки отчёта Выписка банка для ОРТ
   *
   * @returns URL для выгрузки отчёта Выписка банка для ОРТ
   */
  private getDownloadBankStatementReportChecks(): string {
    return environment.baseReportingApiUrl + api.report.bankStatement;
  }

  /**
   * Генерация URL для выгрузки реестра товаров
   *
   * @returns URL для выгрузки реестра товаров
   */
  private getDownloadReportCommodities(): string {
    return environment.baseReportingApiUrl + api.report.commodities;
  }
  /**
   * Генерация URL для выгрузки реестра чеков с документами от ФТС
   *
   * @returns URL для выгрузки реестра чеков с документами от ФТС
   */
  private getFtsChequesAllPath(): string {
    return environment.baseAccountingApiUrl + api.operator.taxFreeFtsAll;
  }

  /**
   * Генерация URL для сохранения комментария для чека
   *
   * @returns URL для сохранения комментария для чека
   */
  private getUpdateChequeCommentPath(): string {
    return environment.baseAccountingApiUrl + api.operator.updateComment;
  }
}
