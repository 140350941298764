<div class="not-found-wrapper">
  <div class="icon-wrapper">
    <svg-icon
      class="icon not-found d-flex flex-column justify-content-center align-items-center"
      src="/assets/icons/not_found.svg"
    ></svg-icon>
  </div>
  <div class="not-found-texts">
    <h4>{{ 'not-found-page-title' | translate }}</h4>
    <p class="uptitle">
      <!-- ссылка home page -->
      <span>{{ 'not-found-page-description' | translate }}</span>
    </p>
  </div>
</div>
