import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getMobileAppLinkWithRef } from 'src/app/utility/getMobileAppLinkWithRef';

/**
 * Компонент футера.
 *
 * @class
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**
   * Текущий год
   *
   * @type {number}
   */
  googlePlayLinkWithRef =
    'https://play.google.com/store/apps/details?id=ru.digitax.droid';
  minioLinkWithRef =
    'https://mediaserver.digitaxfree.ru/media/digitax-taxfree-adhoc.apk';

  showAppButtons = true;

  readonly currentYear = new Date().getFullYear();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  /**
   * Хук инициализации компонента
   */
  ngOnInit(): void {
    this.updateMobileAppLinks();

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(event => {
        if (event.url.includes('instruction/mobile')) {
          this.showAppButtons = false;
        } else {
          this.showAppButtons = true;
        }
      });

    combineLatest([
      this.authService.currentCustomerObserver,
      this.route.queryParamMap
    ]).subscribe(([c, param]) => {
      this.updateMobileAppLinks(c?.referralCode, param.get('referral_code'));
    });
  }

  /**
   * Обновление ссылок на МП
   *
   * @param customerReferralCode - реферальный код покупателя
   * @param paramCode - реферальный код из параметров страницы
   */
  updateMobileAppLinks(customerReferralCode?: string, paramCode?: string) {
    const { googlePlayLinkWithRef, minioLinkWithRef } = getMobileAppLinkWithRef(
      paramCode || customerReferralCode
    );
    this.googlePlayLinkWithRef = googlePlayLinkWithRef;
    this.minioLinkWithRef = minioLinkWithRef;
  }
}
