<div *ngIf="currentUser?.toDelete" class="remove-profile-request-notification">
  {{ 'remove-profile-request-notification' | translate }}
  <span [ngbTooltip]="'remove-profile-request-notification-tooltip' | translate: { date: currentUser.deleteRequestDate | date: 'dd.MM.y' }">deletion</span>
  has been accepted
</div>
<header id="navbar" role="banner">
  <div class="container">
    <div class="header-inner d-flex">
      <button *ngIf="currPath.showTabs && !showMobileLabel" class="navbar-toggler navbar-brand" type="button"
        (click)="toggleMenu()" [ngClass]="menuOpened ? 'opened' : ''" role="button">
        <span *ngIf="hideCloseIcon" class="navbar-toggler-icon"></span>
      </button>
      <div class="tablet-nav d-flex align-items-center" *ngIf="
          showMobileLabel &&
          currPath.showTabs &&
          !(!currPath.showTabs || currPath.showPointsTab)
        ">
        <span class="button-holder">
          <button class="navbar-toggler navbar-brand" type="button" (click)="toggleMenu()"
            [ngClass]="menuOpened ? 'opened' : ''" role="button">
            <span class="navbar-toggler-icon"></span>
          </button>
        </span>
        <a class="navbar-brand d-none d-sm-none d-md-block d-lg-inline-block d-xl-none" routerLink="/" role="link"><span
            class="logo">
            <img ngSrc='{{minioHost}}/media/assets/portal-header-logo.svg' prioriy width="121" height="49" />
          </span></a>
      </div>
      <div class="navigation-mobile d-flex d-sm-flex d-md-none d-lg-none" *ngIf="showMobileLabel">
        <span (click)="nativePath()" [ngClass]="
            !currPath.showTabs || currPath.showPointsTab ? 'show-arrow' : ''
          "></span><span>{{
          currPath.defaultPathName
          ? currPath.defaultPathName
          : currPath.currentPathName
          }}</span>
      </div>
      <nav role="navigation" class="navbar navbar-expand">
        <a class="navbar-brand d-none d-sm-none d-md-none d-lg-none d-xl-block" routerLink="/" role="link">
          <span class="logo">
            <img ngSrc='{{minioHost}}/media/assets/portal-header-logo.svg' priority width="121" height="49" />
          </span>
        </a>
        <div class="collapse navbar-collapse trans" id="navbarSupportedContent" [ngClass]="menuOpened ? 'opened' : ''">
          <app-mobile-menu #mobileMenu (hideCloser)="iconCloseTrigger($event)" (togglerMenu)="hideChildMenu()"
            [links]="links" [currentUrl]="currPath.currentUrl" *ngIf="showMobileLabel"></app-mobile-menu>
          <ul *ngIf="!showMobileLabel" class="navbar-nav mr-auto">
            <li class="nav-item" *ngFor="let route of links">
              <a class="nav-link" routerLink="{{ route.routerLink }}">{{ getNavItemTitle(route) }}</a>
              <ul class="nav-item-children">
                <div *ngIf="route.children" class="nav-item-children-inner">
                  <li class="child-item" *ngFor="let child of route.children">
                    <a *ngIf="child.routerLink" routerLink="{{ child.routerLink }}" class="trans">{{
                      getNavItemTitle(child) }}</a>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <div class="user-controls d-flex align-items-center justify-content-end">
        <div *ngIf="resizeChecked && !currPath.partnerRoleActived" class="language-select-wrapper">
          <ng-select [clearable]="false" [searchable]="false" [items]="localizations" placeholder="EN"
            [(ngModel)]="selectedLocalization" [hideSelected]="true" (change)="onLocalizationSelect()">
          </ng-select>
        </div>
        <app-indicator-notifications></app-indicator-notifications>
        <app-user-controls></app-user-controls>
      </div>
    </div>
  </div>
</header>