import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';

import { environment } from 'src/environments/environment';
import { Config, Locale } from 'src/app/core/models';
import { Subscriber } from 'rxjs';
import { api } from 'src/app/config';

const params = new URLSearchParams();

/**
 * Сервис авторизации
 */
@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  /**
   * Параметры запроса
   *
   * @type {{ withCredentials: boolean }}
   */
  httpOptions: { withCredentials: boolean } = {
    withCredentials: true
  };
  /**
   * Ключ признака попытки регистрации в local storage
   */
  private readonly registrationAttemptIdentifier = 'REGISTRATION_ATTEMPT';

  /**
   * Конфиг приложения
   *
   * @type {Config}
   */
  private config: Config;

  constructor(private http: HttpClient) {}

  /**
   * Запрос на авторизацию пользователя
   *
   * @param username логин
   * @param password пароль
   * @returns observable объект запроса
   */
  login(username: string, password: string): Observable<void> {
    params.append('username', username);
    params.append('password', password);
    return this.http.post<void>('/login', params.toString()).pipe(
      map(() => {
        localStorage.removeItem('login');
      })
    );
  }

  /**
   * Запрос на регистрацию пользователя
   *
   * @param email логин
   * @param captcha капча
   * @param referralCode реферальная ссылка
   * @param locale локализация пользователя на момент регистрации
   * @returns observable объект запроса
   */
  register(
    email: string,
    captcha: string,
    referralCode: string | null,
    locale: Locale
  ): Observable<{ loginType: string }> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.post<{ loginType: string }>(
          this.getRegisterUrl(),
          { email, captcha, referralCode, system: 'PORTAL', locale },
          this.httpOptions
        );
      })
    );
  }

  /**
   * Запрос на повторную отправку ссылки на регистрацию
   *
   * @param email логин
   * @param isRegistration - призна процесса регистрации
   * @returns observable объект запроса
   */
  againSendRegisterLink(
    email: string,
    isRegistration: boolean = false
  ): Observable<void> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.post<void>(
          this.getAgainSendRegisterLinkPath(),
          {
            email,
            isRegistration
          },
          this.httpOptions
        );
      })
    );
  }

  /**
   * Запрос на сброс пароля
   *
   * @param email логин
   * @param captcha капча
   * @returns observable объект запроса
   */
  restore(email: string, captcha: string): Observable<void> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.post<void>(
          this.getRestoreUrl(),
          { email, captcha },
          this.httpOptions
        );
      })
    );
  }

  /**
   * Запрос на изменение пароля
   *
   * @param code код из ссылки
   * @param password новый пароль
   * @returns observable объект запроса
   */
  updatePassword(code: string, password: string): Observable<void> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.post<void>(
          this.getUpdatePasswordPath(code),
          { password },
          this.httpOptions
        );
      })
    );
  }

  /**
   * Запрос на получение капчи
   *
   * @returns observable объект запроса
   */
  getCaptcha(): Observable<Blob> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        const salt = new Date().getTime();
        return this.http.get(`${this.getCaptchaPath()}?${salt}`, {
          responseType: 'blob',
          withCredentials: true
        });
      })
    );
  }

  /**
   * Запрос на подтверждение регистрации или сброса пароля
   *
   * @param code код подтверждения из смс
   * @param phone номер телефона
   * @param password пароль
   * @param mode параметр получения проверочного кода: register - регистрация, forgot - сброс пароля
   * @param captcha капча
   * @returns observable объект запроса
   */
  confirmRegisterBySmsCode(
    code: string,
    phone: string,
    password: string,
    mode: string,
    captcha?: string
  ): Observable<void> {
    this.storeRegistrationAttempt();
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.post<void>(
          mode === 'forgot'
            ? this.getConfirmChangePasswordBySmsCodePath(code, phone)
            : this.getConfirmRegisterBySmsCodePath(code, phone),
          { password, captcha },
          this.httpOptions
        );
      })
    );
  }

  /**
   * Проверка признака попытки регистрации
   *
   * @returns признак попытки регистрации из local storage
   */
  checkRegistrationAttempt(): boolean {
    return JSON.parse(localStorage.getItem(this.registrationAttemptIdentifier));
  }

  /**
   * Запрос на получение информации о пользователе по коду
   *
   * @param code код
   * @returns observable объект запроса
   */
  getRegisterUserByCode(code: string): Observable<void> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        console.log('config:', conf);
        this.setConfig(conf);
        return this.http.get<void>(this.getRegisterUserPath(code));
      })
    );
  }

  /**
   * Запрос на получение информации о пользователе, который сбрасывает пароль, по коду
   *
   * @param code код
   * @returns observable объект запроса
   */
  getRestoreUserByCode(code: string): Observable<void> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.get<void>(this.getRestoreUserByCodePath(code));
      })
    );
  }

  /**
   * Повторная отправка смс
   *
   * @param login логин
   * @param captcha капча
   * @returns observable объект запроса
   */
  resendSms(login: string, captcha?: string): Observable<void> {
    const body = { login };
    if (captcha) {
      body[captcha] = captcha;
    }
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return this.http.post<void>(this.getResendSmsPath(), body);
      })
    );
  }

  /**
   * Установка значения false в признак попытки регистрации в local storage
   */
  clearRegistrationAttempt(): void {
    localStorage.setItem(this.registrationAttemptIdentifier, 'false');
  }

  /**
   * Получение конфига, если он установлен
   *
   * @returns конфиг
   */
  getConfig(): Config {
    if (!this.config) {
      throw new Error('Config not set');
    }
    return this.config;
  }

  /**
   * URL авторизации пользователя
   */
  getLoginPath(): Observable<string> {
    return this.obtainConfig().pipe(
      mergeMap((conf: Config) => {
        this.setConfig(conf);
        return new Observable((observer: Subscriber<string>) => {
          const loginPath = this.config.auth + api.auth.login;
          observer.next(loginPath);
        });
      })
    );
  }

  /**
   * Установка конфига
   *
   * @param config конфиг
   */
  private setConfig(config: Config): void {
    this.config = config;
  }

  /**
   * Получение конфига
   * Для production запрос к API, для других окружений получение из переменной окружения
   *
   * @returns observable объект запроса
   */
  private obtainConfig(): Observable<Config> {
    if (environment.production) {
      return this.http.get<Config>(this.getLoginConfigPath());
    }
    return new Observable((observer: Subscriber<Config>) => {
      const auth = environment.auth.baseApiUrl;
      const portal = environment.basePortalApiUrl;
      const config = new Config({ portal, auth });
      observer.next(config);
      observer.complete();
    });
  }

  /**
   * URL эндпоинта получения конфига
   */
  private getLoginConfigPath(): string {
    return api.auth.loginConfig;
  }

  /**
   * Установка значения true в признак попытки регистрации в local storage
   */
  private storeRegistrationAttempt(): void {
    localStorage.setItem(this.registrationAttemptIdentifier, 'true');
  }

  /**
   * URL эндпоинта регистрации пользователя
   */
  private getRegisterUrl(): string {
    return this.config.portal + api.auth.register;
  }

  /**
   * URL эндпоинта сброса пароля
   */
  private getRestoreUrl(): string {
    return this.config.portal + api.auth.restore;
  }

  /**
   * URL эндпоинта изменения пароля
   *
   * @param code код подтверждения
   */
  private getUpdatePasswordPath(code: string): string {
    return this.config.auth + api.auth.updatePassword.replace('{code}', code);
  }

  /**
   * URL эндпоинта подтверждения регистрации через смс-код
   *
   * @param code код подтверждения
   * @param login логин
   */
  private getConfirmRegisterBySmsCodePath(code: string, login: string): string {
    return (
      this.config.auth +
      api.auth.confirmRegisterBySmsCode
        .replace('{code}', code)
        .replace('{login}', login)
        .replace(/\+/g, '%2B')
    );
  }

  /**
   * URL эндпоинта подтверждения изменения пароля через смс-код
   *
   * @param code код подтверждения
   * @param login логин
   */
  private getConfirmChangePasswordBySmsCodePath(
    code: string,
    login: string
  ): string {
    return (
      this.config.auth +
      api.auth.confirmUpdateBySmsCode
        .replace('{code}', code)
        .replace('{login}', login)
        .replace(/\+/g, '%2B')
    );
  }

  /**
   * URL эндпоинта получения капчи
   */
  private getCaptchaPath(): string {
    return this.config.auth + api.auth.captcha;
  }

  /**
   * URL эндпоинта повторной отправки ссылки на регистрацию
   */
  private getAgainSendRegisterLinkPath(): string {
    return this.config.auth + api.auth.againSendRegisterLink;
  }

  /**
   * URL эндпоинта повторной отправки смс
   */
  private getResendSmsPath(): string {
    return this.config.auth + api.auth.resendSms;
  }

  /**
   * URL эндпоинта получения информации о пользователе по коду
   *
   * @param code код
   */
  private getRegisterUserPath(code: string): string {
    return (
      this.config.auth + api.auth.registerUserByCode.replace('{code}', code)
    );
  }

  /**
   * URL эндпоинта получения информации о пользователе, который сбрасывает пароль, в по коду
   *
   * @param code код
   */
  private getRestoreUserByCodePath(code: string): string {
    return (
      this.config.auth + api.auth.restoreUserByCode.replace('{code}', code)
    );
  }
}
