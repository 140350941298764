<div class="cookie-content-wrapper" *ngIf="isShowing">
  <h4 class="cookie-title">Сookie Policy</h4>
  <p class="cookie-description">
    <span>{{ 'cookie-text-first-string' | translate }}</span><br />
    <span>{{ 'cookie-text-second-string' | translate }}</span><br />
    <span>{{ 'cookie-text-third-string' | translate }}</span>
    <a routerLink="/about/terms">{{ 'cookie-text-link' | translate }}</a>
  </p>
  <div class="submit-btn-wrapper">
    <button
      class="btn btn-primary submit"
      (click)="closePopup()"
    >
      {{ 'cookie-submit-btn' | translate }}
    </button>
    <button (click)="closePopup()" class="btn close">X</button>
  </div>
</div>