import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { Passport } from 'src/app/core/models';
import { UserService } from 'src/app/core/services';
import { NavigationMobileService } from 'src/app/core/services/navigation-mobile.service';
import { AbstractFormComponent } from 'src/app/core/classes/AbstractFormComponent';
import { EventBusService } from 'src/app/core/services/event-bus.service';
import {
  CUSTOMER,
  OPERATOR,
  PARTNER, PAYMENT_OPERATOR,
  PORTAL_ADMIN,
  REPORT_ADMIN,
  USER
} from '../../../../core';
/**
 * Компонент контролов в хедере в мобильной устройстве
 */
@Component({
  selector: 'app-user-controls',
  templateUrl: './user-controls.component.html',
  styleUrls: ['./user-controls.component.scss']
})
export class UserControlsComponent extends AbstractFormComponent
  implements OnInit {
  /**
   * Паспортные данные пользователя
   *
   * @type {Passport}
   */
  passport: Passport;
  /**
   * Имя
   *
   * @type {string}
   */
  firstName: string;
  /**
   * Фамилия
   *
   * @type {string}
   */
  lastName: string;
  /**
   * E-mail
   *
   * @type {string}
   */
  email: string;
  /**
   * Телефон
   *
   * @type {string}
   */
  phone: string;
  /**
   * Текущий url
   *
   * @type {string}
   */
  url: string;
  /**
   * Признак, что пользователь залогинен
   *
   * @type {boolean}
   */
  isLoggedIn = false;
  /**
   * URL профиля
   *
   * @type {string}
   */
  profileUrl: string;
  /**
   * Название торговой организации
   *
   * @type {string}
   */
  tradingOrgName: string;
  /**
   * Признак покупателя
   *
   * @type {boolean}
   */
  isCustomer: boolean;
  /**
   * Признак Администратора отчетов
   *
   * @type {boolean}
   */
  isReportAdmin: boolean;
  /**
   * Набор ролей пользователя
   *
   * @type {any}
   */
  currentRole: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public currPath: NavigationMobileService,
    private router: Router,
    private communication: EventBusService,
  ) {
    super();
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    this.authService.userRoleChange.subscribe(user => {
      if (user.includes(USER)) {
        this.isCustomer = true;
      }
    });
    if (this.authService.isLoggedIn()) {
      this.setRoleDropdown();
      this.subscribeOnRouter();
      this.isLoggedIn = true;
      this.obtainData();
      // Подписка на обновление паспортных данных
      this.communication.userPassportUpdateEvent.subscribe(() =>
        this.obtainData()
      );
    }
  }

  /**
   * Подписка на событие навигации роутера
   */
  subscribeOnRouter() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(event => {
        this.isReportAdmin =
          this.authService.currentUserRole &&
          this.authService.currentUserRole.includes(PORTAL_ADMIN);
        this.isCustomer =
          this.authService.currentUserRole &&
          this.authService.currentUserRole.includes(CUSTOMER);
        this.url = event.url;
      });
  }

  /**
   * Редирект на url
   *
   * @param e url
   */
  forwardLint(e) {
    this.router.navigate([e]);
  }

  /**
   * Выход из системы
   */
  logout(): void {
    this.authService.logout();
  }

  /**
   * Загрузка данных
   */
  obtainData() {
    this.obtaining = true;
    this.userService.getCustomerAndAccountSettings().subscribe(customer => {
      if (
        customer &&
        customer.passport !== null &&
        customer.passport !== undefined
      ) {
        this.firstName = customer.passport.name;
        this.lastName = customer.passport.surname;
      }
      this.email = customer.accountSettings.email;
      this.phone = customer.accountSettings.phone;
      this.obtaining = false;
    });
  }

  /**
   * Метод простановки дефолтных значений для хлебных крошек
   *
   * @description очищает все пути и проставляет признак отображения иконки гамбургер-меню
   */
  removeAllPath() {
    this.currPath.defaultPathName = null;
    this.currPath.currentPathName = '';
    this.currPath.showTabs = true;
  }

  /**
   * Получение лейбла для кнопки юзера
   *
   * @returns лейбл, по данным юзера
   */
  getMenuLabel(): string {
    const name = [this.firstName, this.lastName].filter(x => x).join(' ');
    return name || this.email || this.phone;
  }

  /**
   * Простановка данных пользователя для пунктов меню
   */
  setRoleDropdown() {
    if (!this.authService.isLoggedIn()) {
      return;
    }
    if (this.authService.currentUser) {
      this.setUrls(this.authService.currentUser);
      return;
    }
    this.authService
      .obtainAuthUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.setUrls(user);
      });
  }

  /**
   * Простановка URL на пункт меню Кабинет
   *
   * @param user пользователь
   */
  private setUrls(user: any) {
    if (user.details.tradingNetwork) {
      this.tradingOrgName = user.details.tradingNetwork;
    }
    this.currentRole = user.roles;
    this.isReportAdmin = this.currentRole.includes(PORTAL_ADMIN);
    const roleProfileUrl = {
      [PORTAL_ADMIN]: '/admin/profile',
      [PARTNER]: '/partner',
      [CUSTOMER]: '/profile',
      [OPERATOR]: '/operator/info',
      [PAYMENT_OPERATOR]: '/operator/info',
      [REPORT_ADMIN]: '/reports'
    };
    for (const role of Object.keys(roleProfileUrl)) {
      if (this.currentRole.includes(role)) {
        this.profileUrl = roleProfileUrl[role];
        break;
      }
    }
  }
}
