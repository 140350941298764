import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ViewChild,
  HostListener,
  OnDestroy
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  NavigationMobileService,
  LocalizationService,
  AuthService
} from '@app/core/services';
import {
  ADMIN,
  HEADER_LINKS,
  OPERATOR,
  PAYMENT_OPERATOR,
  PORTAL_ADMIN,
  PROFILE
} from '@app/core/constants';
import { localizations } from '@app/core/constants/localization';
import { ILocalization, User } from '@app/core/models';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare global {
  interface Window {
    jivo_destroy: any;
    jivo_onLoadCallback: any;
  }
}
/**
 * Компонент хедера
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Компонент мобильного меню
   *
   * @type {MobileMenuComponent}
   */
  @ViewChild('mobileMenu') mobileMenu: MobileMenuComponent;
  /**
   * Признак открытого меню
   *
   * @type {boolean}
   */
  menuOpened = false;
  /**
   * Признак планшета
   *
   * @type {boolean}
   */
  showMobileLabel: boolean;
  /**
   * Объект текущей локализации приложения
   *
   * @type {ILocalization}
   */
  currentLocalization: ILocalization;
  /**
   * Выбранная локализация в выпадающем списке
   *
   * @type {ILocalization}
   */
  selectedLocalization: ILocalization;
  /**
   * Признак скрытия иконки Закрыть
   *
   * @type {boolean}
   */
  hideCloseIcon = true;
  /**
   * Список локализаций для выпадающего списка
   *
   * @type {ILocalization[]}
   */
  localizations = localizations;
  /**
   * Роуты для меню хедера
   *
   * @type {any}
   */
  links = HEADER_LINKS;
  /**
   * Признак, выполненной проверки на мобильное устройство
   *
   * @type {boolean}
   */
  resizeChecked = false;
  /**
   * Текущий пользователь
   *
   * @type {User}
   */
  currentUser: User;
  /**
   * хост сервиса хранения статических ресурсов
   */
  minioHost: string;
  /**
   * Subject для завершения всех подписок при уничтожении компонента
   *
   * @type {Subject<void>}
   */
  private destroy$ = new Subject<void>();

  constructor(
    public currPath: NavigationMobileService,
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.currPath.currentUrl = event.urlAfterRedirects;
    });
    this.minioHost = environment.minioHost;
  }

  /**
   * Обработчик закрытия вкладки или нажатия на кнопку назад
   */
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.nativePath();
  }

  /**
   * Обработчик изменения размеров окна
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showMobileLabel = window.innerWidth <= 1199;
    this.resizeChecked = true;
  }

  /**
   * Хук инициализации
   */
  ngOnInit(): void {
    this.checkLocalization();
    this.authService.userObtainedObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.currentUser = user;
      });

    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        this.onResize();
      }, 1);
    }
    this.translate.onLangChange.subscribe(v => {
      this.selectedLocalization = localizations.find(
        loc => loc.localeId === v.lang
      );
    });
    this.subscribeForJivoWidget();
  }

  /**
   * Хук дестроя
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Получение локализованного названия для элемента навигации
   *
   * @param item элемент навигации
   * @returns локализованное название
   */
  getNavItemTitle(item): string {
    if (!item.lang) {
      return item.title;
    }
    if (this.currPath.partnerRoleActived) {
      return item.lang['ru'] || item.title;
    }
    return item.lang[this.translate.currentLang] || item.title;
  }

  /**
   * Установка локализации из URL
   */
  checkLocalization() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      let hasChangedLocalization = false;

      localizations.forEach(localization => {
        if (this.router.url.includes(`/${localization.localeId}`)) {
          this.localizationService.setCurrentLocalization(localization);
          hasChangedLocalization = true;
          this.router.navigateByUrl(
            event.url.replace(/(\/ru|\/zh|\/uz|\/en)/g, '/'),
            { replaceUrl: true }
          );
        }
      });

      if (!hasChangedLocalization) {
        this.currentLocalization = this.localizationService.getLocalization();
        this.localizationService.setCurrentLocalization(
          this.currentLocalization
        );
        this.selectedLocalization = this.currentLocalization;
      }
    });
  }

  /**
   * Обработчик изменения состояния мобильного меню
   */
  toggleMenu() {
    this.menuOpened = !this.menuOpened;
    if (!this.menuOpened && this.mobileMenu) {
      this.mobileMenu.hideChildren();
    }
    document.body.classList.toggle('body-overflow-mobile');
  }

  /**
   * Изменение состояния мобильного меню
   */
  hideChildMenu() {
    this.menuOpened = !this.menuOpened;
  }

  /**
   * Обработчки закрытия меню
   *
   * @param e событие клика
   */
  iconCloseTrigger(e) {
    if (this.menuOpened) {
      this.hideCloseIcon = e;
    }
  }

  /**
   * Обработчик выбора локализации из выпадающего списка
   *
   * @description устанавливает новое значение текущей локализации
   */
  onLocalizationSelect() {
    this.localizationService.setCurrentLocalization(this.selectedLocalization);
  }

  /**
   * Обработка кнопки назад
   *
   * @description Проставляет хлебные крошки
   */
  nativePath() {
    if (this.currPath.preventUrl) {
      this.router.navigate([this.currPath.preventUrl]);
    }
    // для табов, возвращает родительский title
    if (this.currPath.showSecondTabs) {
      this.currPath.defaultPathName = this.currPath.tempCurrentPathName;
      this.currPath.showSecondTabs = false;
      this.currPath.showPointsTab = true;
      return;
    }
    if (this.currPath.showPointsTab) {
      this.currPath.showPointsTab = false;
      this.currPath.showTabs = true;
      return;
    }
    // для профиля, возвращает редактирование профиля
    if (this.currPath.currentParentName === PROFILE) {
      switch (this.translate.currentLang) {
        case 'ru': {
          this.currPath.defaultPathName = 'Редактирование профиля';
          break;
        }
        case 'en':
        case 'uz': {
          this.currPath.defaultPathName = 'Edit Profile';
          break;
        }
        case 'zh': {
          this.currPath.defaultPathName = '编辑个人资料';
          break;
        }
        default: {
          break;
        }
      }
      this.currPath.showPointsTab = false;
      this.currPath.showTabs = true;
    }
  }

  /**
   * Добавления коллбэка функции загрузи Jivo Widget и доп логика для скрытия виджета
   *
   */
  private subscribeForJivoWidget(): void {
    // @todo найти другой способ вызова callback загрузки виджета
    window.jivo_onLoadCallback = () => {
      const currentUserRoles = this.authService.currentUser.roles;
      const isScript = document.getElementById('widget-script');
      if (
        currentUserRoles &&
        isScript &&
        (currentUserRoles.includes(OPERATOR) ||
          currentUserRoles.includes(ADMIN) ||
          currentUserRoles.includes(PORTAL_ADMIN) ||
          currentUserRoles.includes(PAYMENT_OPERATOR))
      ) {
        window.jivo_destroy();
      }
    };
  }
}
