import {
  APP_ID,
  importProvidersFrom,
  Inject,
  NgModule,
  PLATFORM_ID
} from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule
} from '@angular/platform-browser';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HeaderComponent,
  UserControlsComponent,
  IndicatorNotificationsComponent,
  CookiePolicyPopupComponent,
  UpdateEmailComponent
} from './modules/shared/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModuleModule } from './modules/shared/shared-module.module';
import { WebsocketModule } from './modules/websocket/websocket.module';
import { environment } from 'src/environments/environment';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { SetAuthHeaderInterceptor } from './core/interceptors/set-auth-header.interceptor';
import localeChine from '@angular/common/locales/zh';
import localeRu from '@angular/common/locales/ru';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { MetaModule } from './modules/meta/meta.module';
import { CountersModule } from './modules/counters/counters.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { WidgetComponent } from './modules/widget/components/widget/widget.component';
import { WidgetLoginComponent } from './modules/widget/components/widget-login/widget-login.component';
import { MetrikaModule } from 'ng-yandex-metrika';
import { NgOptimizedImage } from '@angular/common';
import {
  CanDeactivateGuard
} from './modules/customer-operator/pages/account-operator-checks/operator-checks-info/operator-checks-info.guard';
import {
  provideTranslateService,
  TranslateDirective,
  TranslateLoader,
  TranslatePipe,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizationService } from './core/services';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';

registerLocaleData(localeRu);
registerLocaleData(localeChine);

const getYaMapsLang = () => {
  const localeId = JSON.parse(localStorage.getItem('localization'))?.localeId;

  if (localeId) {
    switch (localeId) {
      case 'ru':
        return 'ru_RU';
      default:
        return 'en_US';
    }
  }

  return 'en_US';
};

/**
 * http loader
 *
 * @param http клиент http
 */
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

const yaMapsConfig: YaConfig = {
  apikey: 'a77b23f3-62c7-48eb-9b45-401c28e056e2',
  lang: getYaMapsLang()
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserControlsComponent,
    IndicatorNotificationsComponent,
    UpdateEmailComponent,
    CookiePolicyPopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    AppRoutingModule,
    MatSelectModule,
    OAuthModule.forRoot(),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    WebsocketModule.config({
      url: environment.websocket
    }),
    FormsModule,
    ReactiveFormsModule,
    MetaModule,
    CountersModule,
    AngularSvgIconModule.forRoot(),
    MetrikaModule.forRoot(
      environment.yandexMetrikaOn ? { id: 89521800 } : { id: null }
    ),
    NgOptimizedImage,
    TranslatePipe,
    TranslateDirective
  ],
  providers: [
    importProvidersFrom(AngularYandexMapsModule.forRoot(yaMapsConfig)),
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetAuthHeaderInterceptor,
      multi: true
    },
    DigitOnlyModule,
    CanDeactivateGuard,
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: false
    })
  ],
  bootstrap: [AppComponent],
  entryComponents: [WidgetComponent, WidgetLoginComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    private localizationService: LocalizationService,
    private translate: TranslateService
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
    this.translate.addLangs(['ru', 'en', 'zh', 'uz']);
    const loc = this.localizationService.getLocalization();
    this.translate.setDefaultLang('en');
    if (loc) {
      this.translate.use(loc.localeId);
    }
  }
}
