import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { RefundPassport } from '../models';
import { TaxFreeCheque } from '../models/TaxFreeCheque';
import { DESC } from '../constants';
/**
 * Сервис для получения статусов компенсирования
 */
@Injectable({
  providedIn: 'root'
})
export class ShopperService {
  /**
   * Http Опции
   */
  httpOptions = {};
  /**
   * Параметры сортировки
   */
  sortParams = {
    params: {
      sortType: 'issuedDate',
      sortOrder: DESC
    }
  };

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.makeHttpOptionsByAuthState(
      this.authService.isLoggedIn()
    );
  }

  /**
   * Получение статуса компенсирования по номеру чека
   *
   * @param formId номер чека
   * @param captcha код капчи
   * @returns Наблюдателя за получением статуса компенсирования по номеру чека
   */
  getTraceById(formId: string, captcha: string): Observable<TaxFreeCheque[]> {
    const options = Object.assign(this.httpOptions, this.sortParams);
    return new Observable(observer => {
      this.http
        .post<any>(this.getTraceByIdPath(), { id: formId, captcha }, options)
        .subscribe(
          res => {
            let data = [];
            if (res) {
              data = res.id
                ? [new TaxFreeCheque(res)]
                : res.map(item => new TaxFreeCheque(item));
            }
            observer.next(data);
          },
          err => observer.error(err)
        );
    });
  }

  /**
   * Получение статуса чека по телефону
   *
   * @param phone телефон
   * @param code код подтверждения
   * @returns Наблюдателя за получением статуса компенсирования по телефону
   */
  getTraceByPhone(phone: string, code: string): Observable<TaxFreeCheque[]> {
    const options = Object.assign(this.httpOptions, this.sortParams);
    return new Observable(observer => {
      this.http
        .post<any>(
          this.getTraceByPhonePath(),
          { phoneNumber: phone, code },
          options
      )
        .subscribe(
          res => {
            const data = res.map(item => new TaxFreeCheque(item));
            observer.next(data);
          },
          err => {
            observer.error(err);
          }
        );
    });
  }

  /**
   * Получение кода смс для подтверждения
   *
   * @param phone номер телефона
   * @param captcha капча
   * @returns Наблюдателя за получением кода подтверждения
   */
  getSmsCodeByPhone = (phone: string, captcha: string): Observable<any> => {
    const options = this.makeHttpOptionsByAuthStateAsText(
      this.authService.isLoggedIn()
    );
    return this.http.post(
      this.getConfirmSmsCodePath(),
      { phoneNumber: phone, captcha },
      options
    );
  };

  /**
   * Получение статуса компенсирования по Email
   *
   * @param email эл.почта
   * @param captcha код капчи
   * @returns Наблюдателя за получением статуса компенсирования по Email
   */
  getTraceByEmail(email: string, captcha: string): Observable<any> {
    const options = Object.assign(this.httpOptions, this.sortParams);
    return new Observable(observer => {
      this.http
        .post<any>(this.getTraceByEmailPath(), { email, captcha }, options)
        .subscribe(
          res => observer.next(res),
          err => observer.error(err)
        );
    });
  }

  /**
   * Получение статуса компенсирования по паспорту
   *
   * @param passport данные паспорта
   * @returns Наблюдателя за получением статуса компенсирования по паспорту
   */
  getTraceByPassport(passport: RefundPassport): Observable<TaxFreeCheque[]> {
    const options = Object.assign(this.httpOptions, this.sortParams);
    return new Observable(observer => {
      this.http
        .post<any>(this.getTraceByPassportPath(), passport, options)
        .subscribe(
          res => {
            const data = res.map(item => new TaxFreeCheque(item));
            observer.next(data);
          },
          err => observer.error(err)
        );
    });
  }
  /**
   * Генерация Http опций по признаку залогинненого пользователя
   *
   * @param isLoggedIn признак, залогинен ли пользователь
   * @returns Http опции
   */
  private makeHttpOptionsByAuthState(isLoggedIn: boolean = false) {
    const options: { withCredentials: boolean; headers?: HttpHeaders } = {
      withCredentials: true
    };
    if (isLoggedIn) {
      options.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      });
    }
    return options;
  }

  /**
   * Генерация Http опций для запроса с текстовым ответом
   *
   * @param isLoggedIn признак, залогинен ли пользователь
   * @returns объект http опций
   */
  private makeHttpOptionsByAuthStateAsText(isLoggedIn: boolean = false) {
    const options: {
      responseType: 'json';
      withCredentials: boolean;
      headers?: HttpHeaders;
    } = {
      responseType: 'text' as 'json',
      withCredentials: true
    };
    if (isLoggedIn) {
      options.headers = new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      });
    }
    return options;
  }
  /**
   * Генерация URL для получения статуса по номеру чека
   *
   * @returns URL для получения статуса по номеру чека
   */
  private getTraceByIdPath(): string {
    return environment.baseAccountingApiUrl + api.shoppers.traceById;
  }
  /**
   * Генерация URL для получения статуса по телефону
   *
   * @returns URL для получения статуса по телефону
   */
  private getTraceByPhonePath(): string {
    return environment.baseAccountingApiUrl + api.shoppers.traceByPhone;
  }
  /**
   * Генерация URL для получения по смс кода подтверждения
   *
   * @returns URL для получения по смс кода подтверждения
   */
  private getConfirmSmsCodePath(): string {
    return environment.baseAccountingApiUrl + api.shoppers.getConfirmSms;
  }
  /**
   * Генерация URL для получения статуса по Email
   *
   * @returns URL для получения статуса по Email
   */
  private getTraceByEmailPath(): string {
    return environment.baseAccountingApiUrl + api.shoppers.traceByEmail;
  }
  /**
   * Генерация URL для получения статуса по паспорту
   *
   * @returns URL для получения статуса по паспорту
   */
  private getTraceByPassportPath(): string {
    return environment.baseAccountingApiUrl + api.shoppers.traceByPassport;
  }
}
