/* eslint-disable jsdoc/check-indentation */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { CaptchaError } from './yandex-smart-captcha.types';
import { YandexSmartCaptchaService } from './yandex-smart-captcha.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component displays a Yandex SmartCaptcha - A service for verifying
 * queries to identify user requests and block bots.
 *
 * Usage example:
 *
 * @example
 * <yandex-smart-captcha
 *   [clientKey]=""
 *   (onSuccess)="console.log($event)"
 * ></yandex-smart-captcha>
 */
@Component({
  selector: 'yandex-smart-captcha',
  standalone: true,
  template: '<div #captcha></div>'
})
export class YandexSmartCaptchaComponent implements AfterViewInit, OnDestroy {
  /**
   * Клиентский ключ
   */
  @Input() clientKey?: string;

  /**
   * Обработчик
   */
  @Output() onCallback: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Обработчик открытия окна с заданием
   */
  @Output() onChallengeVisible: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Обработчик закрытия окна с заданием
   */
  @Output() onChallengeHidden: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Обработчик ошибки сети
   */
  @Output() onNetworkError: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Обработчик ошибки на стороне JS
   */
  @Output() onJavaScriptError: EventEmitter<CaptchaError> = new EventEmitter<
  CaptchaError
  >();

  /**
   * Обработчик успешного прохождения капчи
   */
  @Output() onSuccess: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Обработчик протухшего токена юзера
   */
  @Output() onTokenExpired: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('captcha', { read: ElementRef })
    captcha?: ElementRef<HTMLDivElement>;

  private widgetId?: number;

  constructor(
    private captchaService: YandexSmartCaptchaService,
    private translate: TranslateService
  ) {
    translate.onLangChange.subscribe(() => this.renderCaptcha());
  }

  /**
   * Хук инициализации компонента
   *
   */
  ngAfterViewInit() {
    this.renderCaptcha();
  }
  /**
   * Рендер капчи
   *
   */
  renderCaptcha() {
    this.captchaService
      .renderCaptcha(this, this.captcha.nativeElement, {
        sitekey: this.clientKey,
        hl: this.mapLocaleId(this.translate.currentLang),
        callback: (token: string) => this.onCallback.emit(token)
      })
      .subscribe((id: number) => {
        this.widgetId = id;
      });
  }

  /**
   * Возвращение виджета в изначальное состояние
   */
  reset(): void {
    this.captchaService.reset(this.widgetId);
  }

  /**
   * @returns текущее значение токена юзера
   */
  getResponse(): string {
    return this.captchaService.getResponse(this.widgetId);
  }
  /**
   * Хук финализации
   */
  ngOnDestroy(): void {
    this.captchaService.destroy(this.widgetId);
  }

  /**
   * Получение локализации для капчи
   *
   * @param localeId текущая локализация
   */
  private mapLocaleId(localeId: string) {
    if (localeId === 'zh') {
      return 'en';
    }
    return localeId || 'en';
  }
}
